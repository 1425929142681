import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'time-picker',
  template: `<div class="mb-4">
    <label [for]="controlName" class="block text-sm font-medium text-gray-700"
      >{{ label
      }}<span *ngIf="isRequired() && label !== ''" class="text-red-500">
        *
      </span></label
    >
    <div
      *ngIf="!minuteGap"
      class="flex h-8 mt-1 w-full border border-gray-300 px-4 py-2 items-center rounded-md"
    >
      <input
        class="w-full text-black"
        matInput
        autocomplete="off"
        name="selected_time_A"
        [ngxMatTimepicker]="pickerA"
        placeholder="08:00"
        format="24"
        [formControl]="controlName"
        readonly
      />

      <ngx-mat-timepicker #pickerA></ngx-mat-timepicker>
    </div>
    <div
      *ngIf="minuteGap"
      class="flex h-8 mt-1 w-full border border-gray-300 px-4 py-2 items-center rounded-md"
    >
      <input
        class="w-full text-black"
        matInput
        autocomplete="off"
        name="selected_time_A"
        [ngxMatTimepicker]="pickerA"
        placeholder="08:00"
        format="24"
        [formControl]="controlName"
        readonly
      />
      <ngx-mat-timepicker
        #pickerA
        [minutesGap]="minuteGap"
      ></ngx-mat-timepicker>
    </div>
    <div
      class="invalid-feedback"
      *ngIf="controlName.invalid && controlName.touched"
    >
      <div
        *ngIf="controlName.errors.required"
        class="text-sm text-red-500 mt-1 ml-2"
      >
        This field is required.
      </div>
    </div>
  </div>`,
})
export class TimePickerComponent implements OnInit {
  @Input() label: string = '';
  @Input() minuteGap: number = null;

  @Input() controlName: FormControl = new FormControl('');

  ngOnInit(): void {}
  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
