import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { appConfig } from 'app/core/config/app.config';

@Component({
  selector: 'form-validator',
  template: `<div *ngIf="controlName.invalid && controlName.touched">
    <div
      *ngIf="controlName.errors.required"
      class="text-sm text-red-500 mt-1 ml-2"
    >
      This field is required.
    </div>
    <div
      *ngIf="controlName.errors.minlength"
      class="text-sm text-red-500 mt-1 ml-2"
    >
      This field must be at least
      {{ controlName.errors.minlength.requiredLength }} characters.
    </div>
    <div
      *ngIf="controlName.errors.maxlength"
      class="text-sm text-red-500 mt-1 ml-2"
    >
      This field cannot be longer than
      {{ controlName.errors.maxlength.requiredLength }} characters.
    </div>
    <div
      *ngIf="controlName.errors.email"
      class="text-sm text-red-500 mt-1 ml-2"
    >
      This field must be a valid email.
    </div>
    <div *ngIf="controlName.errors.min" class="text-sm text-red-500 mt-1 ml-2">
      This field must be at least {{ controlName.errors.min.min }}.
    </div>
    <div *ngIf="controlName.errors.max" class="text-sm text-red-500 mt-1 ml-2">
      This field cannot be more than {{ controlName.errors.max.max }}.
    </div>
    <div
      *ngIf="controlName.errors.pattern"
      class="text-sm text-red-500 mt-1 ml-2"
    >
      This field must be a number or alphanumeric.
    </div>
    <div
      *ngIf="controlName.errors.alphaNumeric"
      class="text-sm text-red-500 mt-1 ml-2"
    >
      This field must be alphanumeric.
    </div>
    <div
      *ngIf="controlName.errors.numeric"
      class="text-sm text-red-500 mt-1 ml-2"
    >
      This field must be numeric.
    </div>
  </div>`,
})
export class FormValidatorComponent {
  @Input() controlName: FormControl;
}
