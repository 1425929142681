import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { capitalize, get, replace } from 'lodash';
import { snakeToTitle } from '../utils/string-conversion';

@Component({
  selector: 'crud-field-array',
  template: `
    <div class="flex flex-col mb-4">
      <div class="font-bold">{{ label }}</div>
      <div *ngFor="let val of value">
        <div class="ml-2" *ngIf="!isLink">{{ val ?? '-' }}</div>
        <div class="ml-2 text-blue-600" *ngIf="isLink">
          <a [href]="val" target="_blank">{{ val ?? '-' }}</a>
        </div>
      </div>
    </div>
  `,
})
export class CrudFieldArrayComponent implements OnChanges, OnInit {
  @Input() resource: any;
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() value: [string];
  @Input() isLink: boolean = false;

  constructor() {}

  public ngOnInit(): void {
    if (!this.value) {
      this.isLink = false;
    }

    if (this.label == '') {
      this.label = snakeToTitle(this.name);
    }
  }

  public ngOnChanges(changes): void {
    if (!this.value) {
      this.value = get(this.resource, this.name);
    }
  }
}
