import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'crud-layout-list-with-search',
  template: `
    <div class="w-full">
      <div
        *ngIf="showSearch"
        class="flex flex-col bg-white shadow-md p-6 gap-5"
      >
        <div class="mb-5" *ngIf="filterHeaderTitle">
          <h3 class="text-black font-medium">
            {{ filterHeaderTitle }}
          </h3>
        </div>
        <div
          class="flex w-full flex-row justify-between items-center overflow-auto"
        >
          <div class="flex flex-row gap-3 ">
            <search-input
              style="width: 200px;"
              *ngIf="showSearch"
              [controlName]="SearchControlName"
              [placeholder]="searchPlaceholder"
              (search)="onSearch($event)"
            ></search-input>
            <ng-content select="[header-start]"></ng-content>
            <button
              *ngIf="showReset"
              (click)="reset.emit()"
              type="outline"
              class="h-fit my-auto"
            >
              <span
                class="py-1 pl-2 pr-3 flex items-center gap-1 text-blue-500 font-bold"
              >
                <mat-icon svgIcon="mat_solid:restart_alt"></mat-icon>
                <div>Reset</div>
              </span>
            </button> 
          </div>
          <div class="flex flex-row gap-2">
            <div class="flex flex-row gap-2">
              <ng-content select="[header-end]"></ng-content>
              <button
                *ngIf="showRefresh"
                (click)="refresh.emit()"
                type="outline"
                class="h-fit my-auto"
              >
                <mat-icon svgIcon="mat_solid:refresh"></mat-icon>
              </button>
            </div>
            <button-dropdown
              *ngIf="filters?.length > 0"
              [options]="filters"
              [controlName]="FilterControlName"
              (outputValue)="onSelectOption($event)"
            ></button-dropdown>
          </div>
        </div>
        <ng-content select="[header-full]"></ng-content>
      </div>

      <div class="p-6">
        <div class="mb-5" *ngIf="tableHeaderTitle">
          <h3>
            <b>{{ tableHeaderTitle }}</b>
          </h3>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class LayoutListWithSearchComponent {
  @Input() actionTitle: string;
  @Input() actionLink: string;
  @Input() filters: any[] = [];
  @Input() showSearch: boolean = true;
  @Input() SearchControlName: FormControl = new FormControl('');
  @Input() FilterControlName: FormControl = new FormControl('');
  @Output() search: EventEmitter<void> = new EventEmitter();
  @Output() status: EventEmitter<void> = new EventEmitter();
  @Input() tableHeaderTitle: string = null;
  @Input() filterHeaderTitle: string = null;
  @Input() searchPlaceholder: string = 'Search';

  @Input() showRefresh: boolean = false;
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

  @Input() showReset: boolean = false;
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  @Output() onBtnClick: EventEmitter<void> = new EventEmitter<void>();
  private timeOut: any;

  constructor() {
    this.actionTitle = this.actionTitle ?? 'Add';
    this.actionLink = this.actionLink ?? '';
  }

  public onSearch(event) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.search.emit(event);
    }, 1000);
  }

  public onSelectOption(event) {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.status.emit(event);
    }, 1000);
  }
}

export interface leftBtn {
  value?: string | number;
  name: string;
  type: string;
}
