<div class="mt-10 ml-4 h-13 flex items-center flex-row">
  <p class="font-semibold text-md" *ngIf="contacts">
    Need Help? Contact
    <a
      class="cursor-pointer text-blue-500 hover:underline"
      [href]="'mailto:' + contacts?.email"
    >
      {{ contacts?.email || '-' }}
    </a>
    <br />
    or call us at
    <a
      class="cursor-pointer text-blue-500 hover:underline"
      [href]="'tel:' + contacts?.contact"
    >
      {{ contacts?.contact || '-' }}
    </a>
    .
  </p>
</div>
