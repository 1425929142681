import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Centre } from 'app/services/api/models/centre';

@Component({
  selector: 'parent-contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements AfterViewInit {
  @Input() private centre?: Centre;
  @Input() private email?: string;
  @Input() private contact?: string;

  public contacts: { email: string; contact: string } = null;

  ngAfterViewInit(): void {
    if (this.centre) {
      this.contacts = {
        email: this.centre?.centre_email ?? '-',
        contact: this.centre?.centre_contact ?? '-',
      };
    } else if (this.email && this.contact) {
      this.contacts = {
        email: this.email ?? '-',
        contact: this.contact ?? '-',
      };
    } else {
      this.contacts = {
        email: '-',
        contact: '-',
      };
    }
  }
}
