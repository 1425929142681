import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { capitalize, get, replace } from 'lodash';

@Component({
  selector: 'crud-field-html',
  template: `
    <div class="flex flex-col mb-4 gap-1">
      <div class="font-bold capitalize">{{ label }}</div>
      <div
        class="ml-2"
        [ngClass]="{
          'font-roboto text-base font-medium border border-gray-300 rounded p-2':
            hasBorder
        }"
        [innerHTML]="value ?? '-'"
      ></div>
    </div>
  `,
})
export class CrudFieldHtmlComponent implements OnChanges, OnInit {
  @Input() resource: any;
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() value: string;
  @Input() hasBorder: boolean = false;

  constructor() {}

  public ngOnInit(): void {
    if (this.label == '') {
      this.label = capitalize(replace(this.name, /_/g, ' '));
    }
  }

  public ngOnChanges(changes): void {
    if (!this.value) {
      this.value = get(this.resource, this.name);
    }
  }
}
