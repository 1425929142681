import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseConfigService } from '@fuse/services/config';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { appConfig } from 'app/core/config/app.config';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from 'app/core/user/user.service';
import { PermissionList } from 'app/services/api/enums/permission-list';
import { User } from 'app/services/api/models/user';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'futuristic-layout',
  templateUrl: './futuristic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FuturisticLayoutComponent implements OnInit, OnDestroy {
  layout: string = 'futuristic';
  isScreenSmall: boolean;
  navigation: Navigation;
  user: User;
  public env = environment;
  public permissions: string[] = [];
  public permissionList = PermissionList;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  public app_config = appConfig;

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseConfigService: FuseConfigService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  public get appVersion(): string | null {
    return this.env?.LATEST_VERSION ?? null;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to the user service
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        if (!user) return;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.permissions = user.permissions.map((p) => p.name);
        this.hideNavigation();
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  private hideNavigation(): void {
    const nav = this.navigation[this.layout];

    const permissionsMapping = {
      'referral.group': {
        permissions: [
          this.permissionList.VIEW_E_REFERRAL.toString(),
          this.permissionList.VIEW_EVENT.toString(),
        ],
        children: {
          e_referral: this.permissionList.VIEW_E_REFERRAL,
          scheduer: this.permissionList.VIEW_EVENT,
        },
      },
      'client.group': {
        permissions: [
          this.permissionList.VIEW_WITHDRAWAL.toString(),
          this.permissionList.VIEW_GRADUATE.toString(),
          this.permissionList.VIEW_CLIENT.toString(),
          this.permissionList.VIEW_CLIENT_REPORT.toString(),
        ],
        children: {
          client_list: this.permissionList.VIEW_CLIENT,
          withdrawal: this.permissionList.VIEW_WITHDRAWAL,
          graduate: this.permissionList.VIEW_GRADUATE,
          iep: this.permissionList.VIEW_CLIENT_REPORT,
          internal_transfer: this.permissionList.VIEW_INTERNAL_TRANSFER,
          caregiver_coaching_home_visit:
            this.permissionList.VIEW_CAREGIVER_COACHING,
          leave_of_absence: this.permissionList.VIEW_LEAVE_OF_ABSENCE_FORM,
          caregiver_interview_form:
            this.permissionList.VIEW_CAREGIVER_INTERVIEW,
          client_absence: this.permissionList.VIEW_CLIENT_LEAVE_OF_ABSENCE,
          incident_report: this.permissionList.VIEW_INCIDENT_REPORT,
        },
      },
      'staff.group': {
        permissions: [
          this.permissionList.VIEW_STAFF.toString(),
          this.permissionList.VIEW_STAFF_TEAM.toString(),
        ],
        children: {
          'staff-members': this.permissionList.VIEW_STAFF,
          'staff-team': this.permissionList.VIEW_STAFF_TEAM,
        },
      },
      'finance.group': {
        permissions: [
          this.permissionList.VIEW_FINANCING.toString(),
          this.permissionList.VIEW_FINANCE_SETTINGS.toString(),
        ],
        children: {
          'finance-assignment': this.permissionList.VIEW_FINANCING,
          'finance-setting': this.permissionList.VIEW_FINANCE_SETTINGS,
        },
      },
      'classrooms.group': {
        permissions: [
          this.permissionList.VIEW_CLASS_SESSION.toString(),
          this.permissionList.VIEW_CLASSROOM.toString(),
        ],
        children: {
          schedules: this.permissionList.VIEW_CLASS_SESSION,
          Class: this.permissionList.VIEW_CLASSROOM,
        },
      },
      'transport.group': {
        permissions: [
          this.permissionList.VIEW_TRANSPORT_APPLICATION.toString(),
          this.permissionList.VIEW_TRANSPORT_VEHICLE.toString(),
        ],
        children: {
          application: this.permissionList.VIEW_TRANSPORT_APPLICATION,
          vehicle: this.permissionList.VIEW_TRANSPORT_VEHICLE,
        },
      },
      attendance: {
        permissions: [this.permissionList.VIEW_ATTENDANCE.toString()],
        children: {},
      },
      'communications.group': {
        permissions: [
          this.permissionList.VIEW_EMAIL_BROADCAST.toString(),
          this.permissionList.VIEW_CHAT.toString(),
        ],
        children: {
          broadcasts: this.permissionList.VIEW_EMAIL_BROADCAST,
          'chat-history': this.permissionList.VIEW_CHAT,
        },
      },
      'reports.group': {
        permissions: [this.permissionList.VIEW_REPORT_GENERATOR.toString()],
        children: {
          'report-generator': this.permissionList.VIEW_REPORT_GENERATOR,
        },
      },
      'centre.group': {
        permissions: [this.permissionList.VIEW_CENTRE.toString()],
        children: {},
      },
      'dashboard.group': {
        permissions: [this.permissionList.VIEW_DASHBOARD.toString()],
        children: {},
      },
      'academic.group': {
        permissions: [
          this.permissionList.VIEW_ACADEMIC_YEAR.toString(),
          this.permissionList.VIEW_EVENT.toString(),
        ],
        children: {
          academicYear: this.permissionList.VIEW_ACADEMIC_YEAR,
          calendarEvent: this.permissionList.VIEW_EVENT,
        },
      },
      settings: {
        permissions: [this.permissionList.VIEW_SETTING.toString()],
        children: {},
      },
    };

    try {
      for (let index = nav.length - 1; index >= 0; index--) {
        const item = nav[index];
        const allowedPermissions = permissionsMapping[item.id];

        if (allowedPermissions) {
          if (item.children) {
            item.children = item.children.filter((child) => {
              return this.permissions.includes(
                allowedPermissions.children[child.id]
              );
            });
          }

          if (
            !this.permissions.some((permission) =>
              allowedPermissions.permissions.includes(permission)
            ) ||
            (item.children && item.children.length === 0)
          ) {
            item.disabled = true;
            nav.splice(index, 1);
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  get userInfo() {
    return JSON.parse(localStorage.getItem('user'));
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  /**
   * Expand and narrow the navigation
   */
  expandNavigation(): void {
    if (this.layout === 'futuristic') {
      this._fuseConfigService.config = { layout: 'thin' };
    } else if (this.layout === 'thin') {
      this._fuseConfigService.config = { layout: 'futuristic' };
    }
  }
}
