import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'week-frequency-input',
  template: `<div class="mb-4 form-group">
    <label class="block text-sm font-medium text-gray-700 pb-2">{{
      label
    }}</label>
    <div *ngIf="!readOnly" class="flex flex-row gap-2">
      <button
        *ngFor="let dayBtn of weekButtons; let i = index"
        class="text-center rounded-full w-6 h-6 text-sm"
        [ngClass]="{
                    ' bg-gray-100 text-gray-500':
                        !dayBtn.isActive,
                    'bg-blue-800  text-white':
                        dayBtn.isActive,

                }"
        (click)="setFrequency(dayBtn)"
      >
        <p>{{ dayBtn.id }}</p>
      </button>
    </div>

    <div *ngIf="readOnly" class="flex flex-row gap-2">
      <div
        *ngFor="let dayBtn of weekButtons; let i = index"
        class="text-center w-6 h-6 text-normal font-bold"
        [ngClass]="{
                    'text-gray-600':
                        !dayBtn.isActive,
                    'text-blue-800':
                        dayBtn.isActive,

                }"
      >
        <p>{{ dayBtn.id }}</p>
      </div>
    </div>
  </div>`,
})
export class WeekFrequencyInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Output() outputValue: EventEmitter<any> = new EventEmitter();
  @Input() controlName: FormControl = new FormControl('');
  protected _onDestroy = new Subject<void>();
  public selected = [];
  public weekButtons = [
    { id: 'M', isActive: false },
    { id: 'T', isActive: false },
    { id: 'W', isActive: false },
    { id: 'TH', isActive: false },
    { id: 'F', isActive: false },
  ];

  ngOnInit() {
    this.controlName.valueChanges
      .pipe(takeUntil(this._onDestroy), distinctUntilChanged())
      .subscribe((value) => {
        this.weekButtons = value;
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public findMatch(frenquency: any[], day: string) {
    const match = frenquency.find((d) => d == day);

    if (match) {
      return true;
    } else {
      return false;
    }
  }

  public setFrequency(button) {
    const specificButton = this.weekButtons.find((btn) => btn.id === button.id);
    specificButton.isActive = !specificButton.isActive;
    const days = this.weekButtons.filter((day) => day.isActive == true);
    if (days) {
      const weekdays = days.map((e) => e.id);
      this.outputValue.emit(weekdays);
    } else {
      this.outputValue.emit([]);
    }
  }
}
