import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  template: `<button
    mat-button
    (click)="disabled ? '' : onClick.emit()"
    [ngClass]="{
            'opacity-50 cursor-not-allowed pointer-events-none': disabled,
            'bg-blue-500 hover:bg-blue-700 text-white': type === 'primary',
            'bg-gray-200 hover:bg-gray-400 text-black': type === 'secondary',
            'bg-red-500 hover:bg-red-700 text-white': type === 'danger',
            'bg-emerald-500 hover:bg-emerald-700 text-white':
                type === 'success',
            'bg-white hover:bg-gray-700 border border-blue-500 hover:border-transparent hover:text-black text-blue-500 hover:fill-white':
                type === 'outline',
                'bg-white hover:bg-red-700 border border-red-500 hover:border-transparent hover:text-white text-red-500  hover:fill-white':type==='outline-danger',
                'text-sm font-medium bg-transparent hover:bg-transparent hover:text-blue-700 text-blue-500':
                type === 'link-small',
                'bg-transparent hover:bg-transparent hover:text-red-700 text-red-500':
                type === 'link-danger',
                'bg-white  border-blue-500 text-blue-500':
                type === 'transparent-primary',
                'text-white bg-amber-500 ':
                type === 'tertiary',
                'bg-blue-600  border-blue-600': type === 'header',
                'bg-transparent border-none hover:bg-transparent hover:text-blue-500 text-blue-500':
                    type === 'link',
        }"
    class="font-bold border-2 rounded-md inline-flex items-center"
    [type]="buttonType"
    [disabled]="disabled"
  >
    <span
      *ngIf="prefixIcon || suffixIcon"
      class="py-1 pl-2 pr-3 flex items-center gap-1"
      [ngClass]="{
                'text-white':
                    type === 'primary' ||
                    type === 'danger' ||
                    type === 'header' ||
                    type === 'success',
                'text-black': type === 'secondary',
                'text-black hover:text-white fill-white ': type === 'outline',
            'text-blue-500': type === 'transparent-primary',
            }"
    >
      <mat-icon
        *ngIf="prefixIcon"
        svgIcon="{{ prefixIcon }}"
        [ngClass]="{
                    'text-white':
                        type === 'primary' ||
                        type === 'danger' ||
                        type === 'success',
                    'text-black': type === 'secondary',
                    'text-black hover:fill-white': type === 'outline',
                    'scale-75 fill-blue-500  hover:fill-blue-700 text-blue-500 hover:text-blue-700':
                    type === 'link-small',
                    'fill-red-500 hover:fill-red-700 text-red-500 hover:text-red-700':
                    type === 'link-danger',
                    'text-blue-600': type === 'transparent-primary',
                    'fill-blue-600 hover:fill-blue-500 text-blue-500 hover:text-blue-600':
                        type === 'link',
                }"
      ></mat-icon>
      <div>{{ text }}</div>
      <mat-icon
        *ngIf="suffixIcon"
        svgIcon="{{ suffixIcon }}"
        [ngClass]="{
                    'text-white':
                        type === 'primary' ||
                        type === 'danger' ||
                        type === 'success',
                    'text-black': type === 'secondary',
                    'text-blue-600 hover:fill-white': type === 'outline',
                    'fill-blue-600 hover:fill-blue-600 text-blue-600 hover:text-blue-600':
                        type === 'link' || type === 'transparent-primary',
                    'scale-75 fill-blue-500  hover:fill-blue-700 text-blue-500 hover:text-blue-700':
                        type === 'link-small',
                    'fill-red-500 hover:fill-red-700 text-red-500 hover:text-red-700':
                        type === 'link-danger',
                            'text-blue-500': type === 'transparent-primary',
                }"
      ></mat-icon>
    </span>
    <span
      *ngIf="!prefixIcon && !suffixIcon"
      class="py-1.5 px-3 flex items-center"
      [ngClass]="{
                'text-white':
                    type === 'primary' ||
                    type === 'danger' ||
                    type === 'header' ||
                    type === 'success',
                'text-black': type === 'secondary',
                'hover:text-white text-blue-500': type === 'outline',
                'hover:text-white text-red-500': type === 'outline-danger',

            'text-blue-500': type === 'transparent-primary',
            }"
    >
      <div>{{ text }}</div>
    </span>
  </button>`,
})
export class ButtonComponent implements OnInit {
  @Input() text: string = 'Submit';
  @Input() type: string = 'primary';
  @Input() disabled: boolean = false;
  @Input() prefixIcon: string = null;
  @Input() suffixIcon: string = null;
  @Input() buttonType: string = 'button';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {}
}
