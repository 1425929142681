import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { dateFormatter } from '../utils/date-format';
@Component({
  selector: 'date-picker',
  template: `<div
    [ngClass]="{
      'flex flex-row items-center gap-2 mb-4': position === 'horizontal',
      'mb-4': position === 'vertical',
      'mb-0': position === null
    }"
  >
    <label
      [for]="controlName"
      class="text-sm font-medium text-gray-700 flex-none"
      >{{ label }}
      <span *ngIf="isRequired() && label !== ''" class="text-red-500">
        *
      </span></label
    >
    <div
      *ngIf="type === 'default'; else customStyle"
      class="flex flex-row justify-between mt-1 w-full h-8 border border-gray-300 px-4 items-center rounded-md"
    >
      <input
        class="text-black flex w-full"
        autocomplete="off"
        matInput
        [matDatepicker]="date"
        [placeholder]="placeholder"
        [id]="controlName"
        [name]="controlName"
        [formControl]="controlName"
      />
      <mat-datepicker-toggle [for]="date">
        <mat-icon
          [svgIcon]="'heroicons_outline:calendar'"
          matDatepickerToggleIcon
          class="text-blue-500"
        ></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #date (closed)="onDateSelected()"></mat-datepicker>
    </div>

    <ng-template #customStyle>
      <div
        class="flex border-blue-500 box-border h-9 w-40 items-center border-2 rounded-md relative"
      >
        <input
          matInput
          [matDatepicker]="date"
          [placeholder]="placeholder"
          [id]="controlName"
          [name]="controlName"
          [formControl]="controlName"
          class="w-full pr-10 text-center"
        />
        <mat-datepicker-toggle
          [for]="date"
          class="absolute right-0 top-0 h-full"
          style="transform: translateY(-15%);"
        >
          <mat-icon
            svgIcon="feather:calendar"
            matDatepickerToggleIcon
            class="text-blue-500"
          ></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </div>
    </ng-template>

    <div
      class="invalid-feedback"
      *ngIf="controlName.invalid && controlName.touched"
    >
      <div
        *ngIf="controlName.errors.required"
        class="text-sm text-red-500 mt-1 ml-2"
      >
        This field is required.
      </div>
      <div
        *ngIf="controlName.errors.dateRange"
        class="text-sm text-red-500 mt-1 ml-2"
      >
        This field must be within the valid date range.
      </div>
    </div>
  </div>`,
  providers: [DatePipe],
})
export class DatePickerComponent implements OnInit {
  @Input() label: string = '';
  @Input() controlName: FormControl = new FormControl('');
  @Input() placeholder: string = 'DD/MM/YYYY';
  @Input() type: string = 'default';
  @Input() position: string = 'vertical';
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(private datePipe: DatePipe) {}

  public onDateSelected(): void {
    this.controlName.setValue(
      dateFormatter(this.datePipe, this.controlName.value, 'yyyy-MM-dd')
    );
    this.onSelect.emit(this.controlName.value);
  }
  ngOnInit(): void {
    if (this.controlName.value) {
      this.onDateSelected();
    }
  }
  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
