import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-panel',
  template: `<div>
    <div>
      <div
        class="flex flex-row justify-between items-center w-full py-1 px-4 rounded-t-md font-semibold {{
          fixedClass
        }}"
      >
        <!-- left -->
        <div class="flex gap-2 items-center">
          <checkbox
            *ngIf="checkboxIndex > -1 && checkboxControlName"
            [id]="title"
            [controlName]="checkboxControlName"
            (onClick)="checkboxClicked.emit()"
            label=""
          ></checkbox>
          <p *ngIf="title && !index">
            {{ title }}
            <span *ngIf="isRequired" class="text-red-500"> * </span>
          </p>
          <p *ngIf="title && index">{{ title }} #{{ index }}</p>
        </div>
        <!-- right -->
        <div class="flex flex-row justify-between w-15 items-center">
          <mat-icon
            *ngIf="copyParamVal > -1"
            svgIcon="mat_outline:content_copy"
            class="text-blue-500 icon-size-5 cursor-pointer"
            (click)="executeMethod(copyParamVal, 'copy')"
          >
          </mat-icon>
          <button
            class="mt-1"
            mat-icon-button
            *ngIf="deleteIndex >= 0"
            [disabled]="disableDelete"
            type="button"
            (click)="executeMethod(deleteIndex, 'delete')"
            [ngClass]="disableDelete ? 'cursor-not-allowed' : 'cursor-pointer'"
          >
            <mat-icon svgIcon="mat_outline:delete_outline" class="text-red-500">
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="bg-white border border-slate-200 py-4 px-4 rounded-b-md">
        <ng-content></ng-content>
      </div>
    </div>
  </div>`,
})
export class PanelComponent implements OnInit {
  @Input() title: string;
  @Input() index: number = null;
  @Input() titleClass: string = 'bg-indigo-100 text-indigo-500';

  @Input() copyParamVal: number = -1;
  @Input() deleteIndex: number = -1;
  @Input() checkboxIndex: number = -1;
  @Input() checkboxControlName?: FormControl;
  @Input() disableDelete: boolean = false;
  @Input() isRequired: boolean = false;

  @Output() copyBtnClicked = new EventEmitter<any>();
  @Output() deleteBtnClicked = new EventEmitter<any>();
  @Output() checkboxClicked = new EventEmitter<any>();
  public fixedClass = 'bg-indigo-100 text-indigo-500';
  ngOnInit(): void {}

  executeMethod(value: number, methodName: string) {
    // Emit the event with the provided parameters
    if (value > -1) {
      if (methodName == 'copy') {
        this.copyBtnClicked.emit(value);
      } else if (methodName == 'delete') {
        this.deleteBtnClicked.emit(value);
      }
    }
  }
}
