import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'radio-button',
  template: `
    <section [ngSwitch]="type">
      <!--  -->
      <!-- FORM -->
      <!--  -->
      <div *ngSwitchCase="'form'">
        <label class="block text-sm font-medium text-gray-700">
          {{ label }}
        </label>
        <mat-radio-group
          aria-label="Select an option"
          [formControl]="controlName"
          (change)="change.emit($event)"
          class="{{ groupClass }}"
        >
          <mat-radio-button
            *ngFor="let option of options"
            class="w-fit rounded"
            [value]="optionValue ? option[optionValue] : option.toLowerCase()"
            [disableRipple]="true"
            color="primary"
          >
            <div>
              <span class="text-sm font-bold">
                {{ optionLabel ? option[optionLabel] : option }}
              </span>
              <mat-icon
                *ngIf="!!option?.icon"
                class="icon-size-6"
                [svgIcon]="option.icon"
              ></mat-icon>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <!--  -->
      <!-- DEFAULT -->
      <!--  -->
      <div *ngSwitchDefault class="mb-2">
        <label class="block text-sm font-medium text-gray-700">
          {{ label }}
        </label>
        <div>
          <mat-radio-group
            aria-label="Select an option"
            [formControl]="controlName"
            (change)="change.emit($event)"
            class="{{ groupClass }}"
          >
            <mat-radio-button
              *ngFor="let option of options"
              class=" w-fit pr-2 rounded border-2 "
              [ngClass]="{
                'bg-emerald-100 border-emerald-800 ': option.color == 'emerald',
                'bg-teal-100 border-teal-800': option.color == 'teal',
                'bg-red-100 border-red-800': option.color == 'red',
                'bg-orange-100 border-orange-500 ': option.color == 'amber',
              }"
              [value]="optionValue ? option[optionValue] : option.toLowerCase()"
            >
              <div class="flex flex-row justify-start gap-3 content-center">
                <span
                  class="text-sm font-bold"
                  [ngClass]="{

                              ' text-emerald-600':
                                  option.color == 'emerald',
                              ' text-teal-600':   option.color == 'teal',
                              ' text-red-600':    option.color == 'red',
                              ' text-orange-500':
                                  option.color == 'amber',
                          }"
                >
                  {{ optionLabel ? option[optionLabel] : option }}
                </span>
                <mat-icon
                  [ngClass]="{

                              ' text-emerald-600':
                                  option.color == 'emerald',
                              ' text-teal-600':   option.color == 'teal',
                              ' text-red-600':    option.color == 'red',
                              ' text-orange-500':
                                  option.color == 'amber',
                          }"
                  class="icon-size-6"
                  [svgIcon]="option.icon"
                ></mat-icon>
              </div>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </section>
  `,
})
export class RadioButtonComponent implements OnInit {
  @Input() label: string = 'Select an option';
  @Input() controlName: FormControl = new FormControl('');
  @Input() options: [] = [];
  @Input() optionValue: string = '';
  @Input() optionLabel: string = '';
  @Input() groupClass: string = 'flex flex-row gap-2';
  @Input() type: 'default' | 'form' = 'default';
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {}

  isChecked(optionValue: any, option: any): boolean {
    if (!optionValue) null;
    if (!option) null;
    const comparison = optionValue ? option[optionValue] : option.toLowerCase();
    return this.controlName?.value === comparison;
  }
}
