import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { User } from 'app/services/api/models/user';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  public _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  private _user_id?: BehaviorSubject<User['id']> = new BehaviorSubject<
    User['id']
  >(null);
  private base = '';
  protected headers = {};
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
    this.base = environment.apiUrl;
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json')
      .set('Authorization', `Bearer ${this.accessToken}`);
  }
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    localStorage.setItem('user', JSON.stringify(value));
  }

  get user$(): Observable<User> {
    return this.update();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<User> {
    return this._httpClient.get<User>('api/users').pipe(
      tap((user) => {
        this._user.next(user);
        this._user_id.next(user.id);
      })
    );
  }

  public get user_id(): User['id'] {
    const local = JSON.parse(localStorage.getItem('user')) ?? null;
    return this._user_id?.value ? this._user_id?.value : local?.staff;
  }

  /**
   * Update the user
   *
   * @param user
   */
  update(): Observable<User> {
    return this._httpClient
      .post(this.base + '/me', {
        headers: this.accessToken,
      })
      .pipe(
        switchMap((user: any) => {
          this._user_id.next(user.id);
          this._user.next(user);
          return this._user;
        })
      );
  }

  ngOnDestroy(): void {
    this._user.complete();
    this._user_id.complete();
  }
}
