import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'search-input',
  template: `
    <div>
      <div
        class="relative border ml-auto border-gray-300 bg-white h-9 px-5 rounded-md text-sm focus:outline-none shadow-sm items-center"
      >
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <mat-icon svgIcon="mat_solid:search" class="fill-gray-400"></mat-icon>
        </div>
        <input
          class="w-full rounded-md border-gray-300 pl-7 h-full"
          type="search"
          name="search"
          [placeholder]="placeholder"
          (keyup)="search.emit($event)"
          [formControl]="controlName"
        />
      </div>
    </div>
  `,
})
export class SearchInputComponent {
  @Output() search: EventEmitter<void> = new EventEmitter();
  @Input() controlName: FormControl = new FormControl('');
  @Input() placeholder: string = 'Search';
  constructor() {}
}
