import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { get } from 'lodash';
import { snakeToTitle } from '../utils/string-conversion';

@Component({
  selector: 'crud-field',
  template: `
    <div
      class="flex flex-col mb-4 text-pretty"
      [ngClass]="{ 'break-words': breakWords }"
    >
      <div class="font-normal text-[12px] flex flex-row gap-2">
        <p
          class="mt-[1px] "
          [ngClass]="{ 'whitespace-nowrap': whitespaceNoWrap }"
          *ngIf="label"
        >
          {{ label }}:
        </p>
        <div
          *ngIf="
            !isLink &&
            !isColoredBorderIcon &&
            !isColoredText &&
            valueType == 'array'
          "
          class="flex flex-col"
        >
          <span *ngFor="let val of value" class="text-[13px] font-medium">
            {{ val ?? '-' }}
          </span>
        </div>
        <span
          class="text-[13px] font-medium"
          *ngIf="
            !isLink &&
            !isColoredBorderIcon &&
            !isColoredText &&
            valueType == 'default'
          "
        >
          {{ value ?? '-' }}
        </span>
      </div>
      <!--   <div
                class="ml-2"
                *ngIf="!isLink && !isColoredBorderIcon && !isColoredText"
            >
                {{ value ?? '-' }}
            </div> -->
      <div class="ml-2 text-blue-600" *ngIf="isLink">
        <a [href]="value" target="_blank">{{ value ?? '-' }}</a>
      </div>
      <div
        class="ml-2 mt-2 font-inter text-[13px] font-medium leading-5 normal-nums text-blue-600 rounded border-2 flex w-20 items-center py-1 p-2 flex-shrink-1"
        [style.borderColor]="'#1E56A0'"
        [style.backgroundColor]="isColoredText ? '#EFFBFF' : ''"
        *ngIf="isColoredText"
      >
        <span class="font-bold" [style.color]="'#1E56A0'">{{
          value ?? '-'
        }}</span>
      </div>

      <div
        class="ml-2 mt-2 font-inter text-base font-medium leading-5 normal-nums text-blue-600 rounded border-2 flex w-40 items-center py-1 p-1 flex-shrink-1"
        [style.borderColor]="'#1E56A0'"
        [style.backgroundColor]="isColoredBorderIcon ? '#EFFBFF' : ''"
        *ngIf="isColoredBorderIcon"
      >
        <ng-container *ngIf="value === 'Announcement'">
          <mat-icon
            class="mr-1 ml-2 flex-shrink-0"
            svgIcon="mat_outline:campaign"
            [style.fill]="'#1E56A0'"
          ></mat-icon>
          <span class="font-bold" [style.color]="'#1E56A0'">{{
            value ?? '-'
          }}</span>
        </ng-container>
        <ng-container *ngIf="value === 'Consent Form'">
          <mat-icon
            class="mr-1 ml-2 flex-shrink-0"
            svgIcon="mat_outline:description"
            [style.fill]="'#1E56A0'"
          ></mat-icon>
          <span class="font-bold" [style.color]="'#1E56A0'">{{
            value ?? '-'
          }}</span>
        </ng-container>
        <ng-container *ngIf="value === 'Newsletter'">
          <mat-icon
            class="mr-2 ml-4 flex-shrink-0"
            svgIcon="mat_outline:live_tv"
            [style.fill]="'#1E56A0'"
          ></mat-icon>
          <span class="font-bold" [style.color]="'#1E56A0'">{{
            value ?? '-'
          }}</span>
        </ng-container>
      </div>
    </div>
  `,
})
export class CrudFieldComponent implements OnChanges, OnInit {
  @Input() resource: any;
  @Input() label: string = null;
  @Input() name: string = '';
  @Input() value: string;
  @Input() valueType: string = 'default';
  @Input() isLink: boolean = false;
  @Input() isColoredBorderIcon: boolean = false;
  @Input() isColoredText: boolean = false;
  @Input() whitespaceNoWrap: boolean = true;
  @Input() breakWords: boolean = false;

  constructor() {}

  public ngOnInit(): void {
    if (!this.value) {
      this.isLink = false;
    }

    if (this.label == '') {
      this.label = snakeToTitle(this.name);
    }
  }

  public ngOnChanges(changes): void {
    if (!this.value) {
      this.value = get(this.resource, this.name);
    }
  }
}
