import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'portal-page',
  styleUrls: ['./portal-page.component.scss'],
  template: `
    <div
      class=" w-full h-screen flex justify-center items-center relative overflow-hidden bg-[#F5F5F5]"
    >
      <div class="flex absolute top-8 left-8 items-center">
        <img
          class="w-20 h-12 mr-2"
          src="assets/images/logo/AWWA-logo.png"
          alt=""
        />
        <div class="pt-4">
          <h1><b>EIC Portal</b></h1>
        </div>
      </div>
      <img
        class="w-100 h-70 bottom-[-120px] mr-[80%] mb-[5%] -z--1 opacity-[0.05] absolute"
        src="assets/images/logo/AWWA-logo.png"
        alt=""
      />
      <div class="w-[50%] container-expand flex flex-col pl-10  gap-14">
        <ng-content> </ng-content>
      </div>
      <div
        class="w-[50%] mobile-hidden  h-full flex flex-col relative justify-center items-center bg-[#EAF0FE]"
      >
        <img
          class="w-[60%] mobile-hidden  h-full absolute  xs:hidden"
          src="{{ images }}"
          alt=""
        />
      </div>
    </div>
  `,
})
export class PagePortal implements OnInit {
  @Input() images: string = 'assets/images/illustrations/portalimg.svg';

  ngOnInit(): void {}
}
