<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="bg-slate-50 text-slate-900 drop-shadow print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.futuristic"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationHeader>
    <!-- Logo -->
    <div class="flex items-center gap-5 h-20 p-6 mb-12">
      <img
        class="w-16 mt-3"
        [src]="
          !!env?.production || !app_config?.useDemo
            ? 'assets/images/logo/AWWA-logo.png'
            : 'assets/images/logo/demo_logo.png'
        "
      />
      <div class="pt-5 text-3xl font-semibold leading-tight">
        <h1>
          {{ !!env?.production || !app_config?.useDemo ? 'EIC' : '' }} Portal
        </h1>
        <p *ngIf="!env.production" class="text-base">(UAT)</p>
        <p *ngIf="!!appVersion" class="text-base">Version {{ appVersion }}</p>
      </div>
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationFooter>
    <div
      class="absolute right-0 w-8 h-10 bg-gray-200 rounded-tl-full rounded-bl-full flex justify-center items-center cursor-pointer"
      (click)="expandNavigation()"
    >
      <mat-icon
        class="scale-75 text-black"
        [svgIcon]="'heroicons_outline:arrow-left'"
      ></mat-icon>
    </div>

    <!-- User -->
    <div class="flex items-center w-full px-6 py-8 border-t">
      <user></user>
      <div class="flex flex-col w-full ml-4 overflow-hidden">
        <div
          class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal text-current opacity-80"
        >
          {{ userInfo.staff?.name ?? '-' }}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50"
        >
          {{ userInfo?.email ?? '-' }}
        </div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <app-header></app-header>
  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
