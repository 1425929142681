import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'autocomplete-input',
  template: `<div class="mb-4 form-group">
    <label
      [for]="controlName"
      class="block text-sm font-medium text-gray-700"
      >{{ label }}</label
    >

    <input
      [formControl]="controlName"
      [id]="controlName"
      [name]="controlName"
      [placeholder]="placeholder"
      class="w-full border border-gray-300 py-2 px-4 h-9 rounded-md form-control"
      [ngClass]="{ 'bg-gray-100': controlName.disabled }"
      [class.is-invalid]="controlName.invalid && controlName.touched"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="optionSelected.emit($event)"
      (displayWith)="displayFn($event)"
    >
      <mat-option
        *ngFor="let option of options"
        [value]="optionValue !== '' ? option[optionValue] : option"
      >
        {{ optionLabel !== '' ? option[optionLabel] : option }}
      </mat-option>
    </mat-autocomplete>
  </div>`,
})
export class AutocompleteInputComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() controlName: FormControl = new FormControl('');
  @Input() options = [];
  @Input() optionValue: string = '';
  @Input() optionLabel: string = '';
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

  public displayFn(option: any): string {
    return option && this.optionLabel ? option[this.optionLabel] : option;
  }
}
