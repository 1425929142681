import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  constructor() {}

  breadcrumb$ = new ReplaySubject<BreadcrumbItem>();
}

export interface BreadcrumbItem {
  title: string;
  breadcrumbs: {
    name: string;
    url: string;
    queryParams?: any;
  }[];
}
