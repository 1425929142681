import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'text-input',
  template: `<div *ngIf="position == 'vertical'" class="mb-4 form-group">
      <label [for]="controlName" class="block text-sm font-medium text-gray-700"
        >{{ label
        }}<span *ngIf="isRequired() && label !== ''" class="text-red-500">
          *
        </span></label
      >
      <input
        *ngIf="readOnly; else fillable"
        readonly
        [type]="type"
        autocomplete="off"
        min="0"
        [placeholder]="placeholder"
        [formControl]="controlName"
        [id]="controlName"
        [name]="controlName"
        class="mt-1 h-8 w-full border border-gray-300 py-2 px-4 rounded-md form-control text-black"
        [ngClass]="{ 'bg-gray-100': controlName.disabled }"
        [class.is-invalid]="controlName.invalid && controlName.touched"
      />
      <ng-template #fillable>
        <input
          [type]="type"
          min="0"
          autocomplete="off"
          [placeholder]="placeholder"
          [formControl]="controlName"
          [id]="controlName"
          [name]="controlName"
          class="mt-1 h-8 w-full border border-gray-300 py-2 px-4 rounded-md form-control text-black"
          [ngClass]="{ 'bg-gray-100': controlName.disabled }"
          [class.is-invalid]="controlName.invalid && controlName.touched"
      /></ng-template>
      <form-validator [controlName]="controlName"></form-validator>
    </div>

    <div
      *ngIf="position == 'horizontal'"
      class="mb-4 flex flex-row justify-start items-center gap-3 w-full"
    >
      <label
        *ngIf="label !== ''"
        [class]="labelStyle"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label
        }}<span *ngIf="isRequired() && label !== ''" class="text-red-500">
          *
        </span></label
      >

      <div class="{{ inputBoxStyle }}">
        <input
          *ngIf="readOnly"
          readonly
          [type]="type"
          autocomplete="off"
          min="0"
          [placeholder]="placeholder"
          [formControl]="controlName"
          [id]="controlName"
          [name]="controlName"
          class="mt-1 h-8 border border-gray-300 rounded-md form-control"
          [ngClass]="{ 'bg-gray-100': controlName.disabled }"
          [class.is-invalid]="controlName.invalid && controlName.touched"
        />

        <input
          *ngIf="!readOnly"
          [type]="type"
          autocomplete="off"
          min="0"
          [placeholder]="placeholder"
          [formControl]="controlName"
          [id]="controlName"
          [name]="controlName"
          class="mt-1 h-8 w-full border border-gray-300 py-2 px-4 rounded-md form-control"
          [ngClass]="{ 'bg-gray-100': controlName.disabled }"
          [class.is-invalid]="controlName.invalid && controlName.touched"
        />
      </div>

      <form-validator [controlName]="controlName"></form-validator>
    </div>`,
})
export class TextInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() readOnly: boolean = false;
  @Input() type: string = 'text';
  @Input() controlName: FormControl = new FormControl('');
  @Input() placeholder: string = '';
  @Input() position: string = 'vertical';
  @Input() inputBoxStyle: string = '';
  @Input() labelStyle: string = 'w-fit';

  ngOnInit(): void {}

  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}
