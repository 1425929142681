import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'menu-btn',
  template: `
    <app-button
      [disabled]="disabled"
      [text]="title"
      [type]="type"
      [suffixIcon]="menuSuffixIcon"
      [matMenuTriggerFor]="menu"
    >
    </app-button>
    <mat-menu class="mt-3" #menu="matMenu">
      <ng-container *ngFor="let obj of options; trackBy: trackByFn">
        <button
          mat-menu-item
          [disabled]="disabled"
          (click)="returnItemSelected(obj)"
        >
          <mat-icon
            *ngIf="obj['icon']"
            [ngClass]="{
              ' text-cyan-600': cyan.includes(obj['color']),
              ' text-blue-600': blue.includes(obj['color']),
              ' text-gray-600': gray.includes(obj['color']),
              'text-red-600': red.includes(obj['color']),
              'text-violet-600': violet.includes(obj['color']),
              'text-black': black.includes(obj['color']),
              'bg-amber-100 text-amber-600': amber.includes(obj['color'])
            }"
            svgIcon="{{ obj['icon'] }}"
          ></mat-icon>
          <span
            [ngClass]="{
              ' text-cyan-600': cyan.includes(obj['color']),
              ' text-blue-600': blue.includes(obj['color']),
              ' text-gray-600': gray.includes(obj['color']),
              'text-red-600': red.includes(obj['color']),
              'text-violet-600': violet.includes(obj['color']),
              'text-black': black.includes(obj['color']),
              'bg-amber-100 text-amber-600': amber.includes(obj['color'])
            }"
          >
            {{ obj['title'] }}
          </span>
        </button>
      </ng-container>
    </mat-menu>
  `,
})
export class MenuButtonComponent {
  @Output() onSelect: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() public title: string = 'Menu Button';
  @Input() public type: string = 'transparent-primary';
  @Input() public options: any;
  @Input() menuSuffixIcon: string = null;
  @Input() public disabled: boolean = false;
  public violet = ['violet'];
  public red = ['red'];
  public gray = ['gray'];
  public blue = ['blue'];
  public cyan = ['cyan'];
  public black = ['black'];
  public amber = ['amber'];

  trackByFn(index: number, item: any): any {
    return item.id;
  }

  returnItemSelected(data) {
    if (this.disabled) return;
    this.onSelect.emit(data);
  }
}
