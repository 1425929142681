import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  BreadcrumbItem,
  BreadcrumbService,
} from 'app/services/breadcrumb.service';
import { HeaderButtons, HeaderService } from 'app/services/header.service';

@Component({
  selector: 'app-header',
  template: `<div
    class="items-center justify-between px-4 py-3 bg-white border-b border-gray-200 sm:px-6"
  >
    <div class="flex items-center mb-2" *ngIf="breadcrumbs">
      <span
        *ngFor="
          let breadcrumb of breadcrumbs.breadcrumbs;
          index as indexOfelement;
          last as isLast
        "
      >
        <a
          [routerLink]="breadcrumb.url"
          [queryParams]="breadcrumb?.queryParams"
          [ngClass]="
            indexOfelement == breadcrumbs.breadcrumbs.length - 1
              ? 'text-blue-600'
              : 'text-gray-400'
          "
          class="text-sm"
          >{{ breadcrumb.name }}</a
        >
        <mat-icon
          *ngIf="!isLast"
          id="separator"
          class="h-[12px] min-h-[12px] fill-gray-500"
          svgIcon="mat_solid:arrow_forward_ios"
        ></mat-icon>
      </span>
    </div>
    <div class="flex flex-row w-full justify-between">
      <p class="text-[20px] font-semibold">{{ breadcrumbs?.title }}</p>
      <div class="flex flex-row gap-5" *ngIf="buttons?.length > 0 || buttons">
        <div *ngFor="let button of buttons">
          <app-button
            *ngIf="button.icon; else noIcon"
            prefixIcon="{{ button.icon }}"
            type="{{ button.type }}"
            text="{{ button.title }}"
            (onClick)="emitBtn(button)"
          ></app-button>
          <ng-template #noIcon>
            <app-button
              type="{{ button.type }}"
              text="{{ button.title }}"
              (onClick)="emitBtn(button)"
            ></app-button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>`,
})
export class HeaderComponent implements OnInit {
  breadcrumbs: BreadcrumbItem;
  buttons: HeaderButtons[] = [];
  @Output() outputValue: EventEmitter<void> = new EventEmitter();
  constructor(
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumb$.subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });

    this.headerService.buttons$.subscribe((btn) => {
      this.buttons = [];
      this.buttons.push(...btn);
    });
  }

  emitBtn(data) {
    this.headerService.btnTriggerResponse.next([data]);
  }
}
