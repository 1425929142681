import { style } from '@angular/animations';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { appConfig } from 'app/core/config/app.config';

@Injectable()
export class FuseSplashScreenService
{
    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _router: Router
    )
    {
        const demoLogoDOM =  this._document.body.querySelector('#demo-splash-logo')
        const prodLogoDOM =  this._document.body.querySelector('#prod-splash-logo')
        if(!appConfig.useDemo){
            prodLogoDOM.classList.remove('hidden');
        }else{
            demoLogoDOM.classList.remove('hidden');
        }
        // Hide it on the first NavigationEnd event
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                this.hide();
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(): void
    {
        this._document.body.classList.remove('fuse-splash-screen-hidden');
    }

    /**
     * Hide the splash screen
     */
    hide(): void
    {
        this._document.body.classList.add('fuse-splash-screen-hidden');
    }
}
