import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public btnTriggerResponse = new BehaviorSubject<HeaderButtons[]>([]);
  public buttons$ = new BehaviorSubject<HeaderButtons[]>([]);
  constructor() {}

  resetObservableValues() {
    this.buttons$.next([]);
    this.btnTriggerResponse.next([]);
  }
}

export interface HeaderButtons {
  title: string;
  type:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'success'
    | 'outline'
    | 'link'
    | 'link-danger';
  icon?: string;
}
