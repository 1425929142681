import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { CheckboxTheme } from 'app/services/api/models/checkbox-theme';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'dropdown-checkbox',
  template: `
    <label [for]="controlName" class="block text-sm font-medium text-gray-700"
      >{{ title
      }}<span *ngIf="isRequired() && title !== ''" class="text-red-500">
        *
      </span></label
    >
    <button
      class="rounded-md bg-white py-1 px-1.5 w-full"
      [ngClass]="{
        'border-[2px] border-blue-500 text-blue-500': type == 'primary',
        'border-[1.99px] border-gray-300 text-black': type == 'dark',
        'border-[1px] border-gray-300 text-black': type == 'form',
      }"
    >
      <div class="flex flex-row">
        <mat-select
          #select
          [placeholder]="placeholder"
          [panelClass]="{
            'custome-dropdown-panel-nocheck removecheckbox': !multiple,
            'custome-dropdown-panel dropdowncheckbox': multiple
          }"
          disableOptionCentering
          [formControl]="controlName"
          [multiple]="multiple"
          (selectionChange)="onSeletSubOption($event)"
        >
          <mat-select-trigger
            class="flex flex-row"
            [ngClass]="{
              'text-blue-500 font-bold ': type == 'primary',
              'text-black font-normal': type == 'dark'
            }"
          >
            <div class="mr-1">{{ selectedTitle }}</div>
            <div>
              <span
                *ngIf="controlName.value?.length > 0 && !showValue"
                class="border-2 rounded-full px-2 text-white border-blue-600 bg-blue-600"
              >
                {{ controlName.value?.length || 0 }}
              </span>

              <span
                *ngIf="controlName.value?.length > 0 && showValue"
                class="font-normal px-2"
                [ngClass]="{
                  'border-blue-600 border-2 text-white rounded-full bg-blue-600 ':
                    type == 'primary',
                  'border-none  bg-white text-black ': type == 'dark'
                }"
              >
                {{ controlName.value }}
              </span>
            </div>
          </mat-select-trigger>
          <ul *ngIf="!disableAllCheckbox">
            <li>
              <mat-checkbox
                disableRipple="true"
                [(ngModel)]="allToggle"
                [ngModelOptions]="{ standalone: true }"
                [color]="checkboxConfig.color"
                (change)="allOptions()"
              >
                All
              </mat-checkbox>
            </li>
          </ul>
          <ul
            class="w-full"
            [ngClass]="{
                        'w-full':multiple,
                }"
          >
            <li *ngFor="let item of options">
              <mat-option
                #allSelected
                [disabled]="setDisabled(item)"
                [value]="item['id']"
              >
                <p class="text-blue-600">{{ item['title'] }}</p>
              </mat-option>
            </li>
          </ul>
        </mat-select>
        <mat-icon
          class="text-black block pb-[4px] mr-[-8px] ml-[-12px] icon-size-5"
          [ngClass]="{
            ' text-blue-600': type == 'primary',
            ' text-black': type == 'dark'
          }"
          (click)="select.open()"
          [svgIcon]="'mat_outline:keyboard_arrow_down'"
        >
        </mat-icon>
      </div>
    </button>
  `,
})
export class DropdownCheckboxComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  @Input() public controlName: FormControl = new FormControl('');
  @Output() dropdownCheckbox: EventEmitter<void> = new EventEmitter();
  @Input() public title: string = '';
  @Input() public selectedTitle: string = 'Centre';
  @Input() public placeholder: string = '';
  @Input() public options: dropdownCheckboxOptions[];
  @Input() public startOption: number = null;
  @Input() public endOption: number = null;
  @Input() public disableAllCheckbox: boolean = false;
  @Input() public multiple: boolean = true;
  @Input() public showValue: boolean = false;
  @Input() public type: string = 'primary';
  @Input() public enableMinLimit: boolean = false;
  protected _onDestroy = new Subject<void>();
  public allToggle = false;

  public checkboxConfig: CheckboxTheme = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
  };

  @Input() menuSuffixIcon: string = 'mat_outline:keyboard_arrow_down';
  private selectedValues: any;

  ngOnInit(): void {
    if (this.startOption && this.endOption) {
      this.setOptions(this.startOption, this.endOption);
    }
    if (this.enableMinLimit) {
      this.controlName.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe((values) => {
          this.selectedValues = values;
        });
      // Trigger a value change on initial page load to disable the form when selected is only 1
      this.controlName.setValue(this.controlName.value);
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setOptions(start: number, end: number) {
    let array = [];
    for (let i = start; i <= end; i++) {
      array.push({
        title: `${i}`,
        id: `${i}`,
      });
    }
    this.options = array;
  }

  public allOptions() {
    if (this.allToggle) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    this.dropdownCheckbox.emit();
  }

  public onSeletSubOption(value) {
    let newStatus = true;
    let selected = [];
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      } else {
        selected.push(item.value);
      }
    });
    this.allToggle = newStatus;
    // this.controlName.setValue(selected);
    this.selectedValues = selected;
    this.dropdownCheckbox.emit(value);
  }

  public setDisabled(value: any) {
    if (this.enableMinLimit) {
      if (this.selectedValues?.length === 1) {
        if (this.selectedValues[0] === value.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  public isRequired(): boolean {
    // check form if they have a required validation
    return this.controlName.hasValidator(Validators.required);
  }
}

export interface dropdownCheckboxOptions {
  title: string | number;
  id: string | number;
}
