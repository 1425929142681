<div class="h-full" *ngIf="options?.length > 0">
  <select
    placeholder="Select a value"
    [formControl]="controlName"
    name="status"
    (change)="onSelect($event)"
    [ngClass]="{
            'border-blue-500 text-blue-500 py-[0.450rem] px-3 w-full font-bold border-2 rounded-md':type == 'default',
            ' text-black font-normal p-1 w-full border border-gray-300 rounded h-8':type == 'form',
         }"
  >
    <option disabled selected>
      {{ placeholder }}
    </option>
    <option *ngFor="let option of options" [value]="option['value'] ?? '-'">
      {{ option['label'] ?? '-' }}
    </option>
  </select>
  <div class="mt-0.5" *ngIf="controlName.touched && controlName.invalid">
    <span class="text-sm text-red-500">This field is required.</span>
  </div>
</div>
