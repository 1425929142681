import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { appConfig } from 'app/core/config/app.config';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(url): Observable<SafeUrl> {
    const { secureApiStorage } = appConfig;
    if (!secureApiStorage) return of(url);
    const token = localStorage.getItem('accessToken');
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set('Access-Control-Allow-Origin', '*');

    return this.http.get(url, { headers, responseType: 'blob' }).pipe(
      map((imageBlob: Blob) => {
        const imageUrl = URL.createObjectURL(imageBlob);
        return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      }),
      catchError((error) => {
        return of(null);
      })
    );
  }
}
