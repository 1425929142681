import { Component, Input } from '@angular/core';

@Component({
  selector: 'crud-layout-detail',
  template: `
    <div class="w-full">
      <div
        *ngIf="showActions"
        class="flex bg-white shadow-md p-6 justify-between w-full"
      >
        <ng-content select="[actions]"></ng-content>
      </div>

      <ng-content></ng-content>
    </div>
  `,
})
export class LayoutDetailComponent {
  @Input() actionTitle: string;
  @Input() actionLink: string;
  @Input() showActions: boolean = true;

  constructor() {
    this.actionTitle = this.actionTitle ?? 'Add';
    this.actionLink = this.actionLink ?? '';
  }
}
