import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SearchInputComponent } from './search-input';

@NgModule({
  declarations: [SearchInputComponent],
  imports: [MatIconModule, ReactiveFormsModule],
  exports: [SearchInputComponent],
})
export class AtomModule {
  constructor() {}
}
