import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Client } from 'app/services/api/models/client';
import { dateFormatter } from '../utils/date-format';

@Component({
  selector: 'fee-matters-viewer',
  templateUrl: './fee-matters-viewer.component.html',
  providers: [DatePipe],
})
export class FeeMattersViewerComponent {
  @Input() title: string = 'Fee Matters';
  @Input() client: Client = null;

  constructor(private datePipe: DatePipe) {}

  //-----------------------------------------------------------------------------------
  // Client properties
  //-----------------------------------------------------------------------------------

  get mttAssignments(): any[] {
    return this.client?.means_test_tier_assignments ?? null;
  }

  get mttAssignment() {
    return this.mttAssignments?.[0] ?? null;
  }

  get transportApplication() {
    return this.client?.transport_applications ?? null;
  }

  get adhocSubsidyAssignments(): any[] {
    return this.client?.adhoc_subsidy_assignments ?? null;
  }

  get etsSubsidy() {
    return this.transportApplication?.acknowledgement?.ets_subsidy_tier_client;
  }

  //-----------------------------------------------------------------------------------
  // Boolean properties
  //-----------------------------------------------------------------------------------

  get hasMeansTestTierAssignments(): boolean {
    return !!this.mttAssignments && this.mttAssignments?.length > 0;
  }

  get hasTransportApplication(): boolean {
    return !!this.transportApplication;
  }

  get hasAdhocSubsidyAssignments(): boolean {
    return (
      !!this.adhocSubsidyAssignments && this.adhocSubsidyAssignments?.length > 0
    );
  }

  get hasNoFeeMatters(): boolean {
    return (
      !this.hasMeansTestTierAssignments &&
      !this.hasTransportApplication &&
      !this.hasAdhocSubsidyAssignments
    );
  }
  dateFormat = (date?: string, format: string = 'dd/MM/yyyy') =>
    !date ? null : dateFormatter(this.datePipe, date, format);
}
