import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatSnackBar } from '@angular/material/snack-bar';
import { openSnackBar } from '../utils/snackbar';

@Component({
  selector: 'upload-file',
  template: ` <div>
    <div class="flex flex-row justify-end cursor-pointer">
      <mat-icon
        class="icon-size-5 text-black"
        svgIcon="mat_outline:close"
        (click)="onClose()"
      ></mat-icon>
    </div>
    <label class="block text-lg font-bold text-gray-700">
      {{ title }}
    </label>
    <div class="w-full h-full mt-2 mb-2">
      <ngx-dropzone
        [disableClick]="disableInput"
        [maxFileSize]="5242880"
        (change)="onUploadFile($event)"
        [multiple]="multipleFiles"
        [accept]="fileFilter"
      >
        <ngx-dropzone-label class="flex flex-col items-center p-5">
          <mat-icon
            class="h-10 w-10 mb-3"
            [svgIcon]="'heroicons_outline:upload'"
          ></mat-icon>
          <p class="text-lg text-blue-400 font-medium">
            {{ placeholder }}
          </p>
          <p class="text-base text-gray-400">Or drag & drop it here</p>
        </ngx-dropzone-label>
        <div
          class="flex flex-row gap-2 items-center"
          *ngIf="fileType == 'file'"
        >
          <ngx-dropzone-preview
            ngProjectAs="ngx-dropzone-preview"
            *ngFor="let f of files"
            [file]="f"
            [removable]="true"
            (removed)="onRemove(f)"
          >
            <ngx-dropzone-label>
              <img
                *ngIf="regex(f.name) == 'pdf'"
                class="h-full w-full"
                src="assets/icons/pdf-icon.svg"
              />
              <img
                *ngIf="
                  regex(f.name) == 'doc' ||
                  regex(f.name) == 'docx' ||
                  regex(f.name) == 'document'
                "
                class="h-full w-full"
                src="assets/icons/doc-icon.svg"
              />
              <img
                *ngIf="regex(f.name) == 'xls' || regex(f.name) == 'xlsx'"
                class="h-full w-full"
                src="assets/icons/excel-icon.svg"
              />
              <img
                *ngIf="isImage(f)"
                class="h-full w-full"
                [src]="loadImage(f)"
                [alt]="f.name"
              />
            </ngx-dropzone-label>
          </ngx-dropzone-preview>
          <div class="flex flex-col gap-1 mr-1" *ngFor="let f of files">
            <p class="text-black w-15 text-ellipsis overflow-hidden">
              {{ f.name }}
            </p>
            <p>{{ roundUp(f.size) }}MB</p>
          </div>
        </div>
        <div *ngIf="fileType == 'image'">
          <ngx-dropzone-image-preview
            ngProjectAs="ngx-dropzone-preview"
            *ngFor="let f of files"
            [file]="f"
            [removable]="true"
            (removed)="onRemove(f)"
          >
          </ngx-dropzone-image-preview>
        </div>
      </ngx-dropzone>
    </div>
    <label
      *ngIf="alertMessage"
      class="block text-sm font-medium text-red-500 whitespace-pre-line"
    >
      {{ alertMessage }}
    </label>
    <label class="block text-sm font-medium text-gray-700">
      Maximum size allowed: 5 MB
    </label>
    <label
      *ngIf="maxFileNumber > 0"
      class="block text-sm font-medium text-gray-700"
    >
      Maximum number of files allowed to be submitted: {{ maxFileNumber }}
    </label>

    <section *ngIf="!!isFinanceSetting" class="flex w-fulll flex-col mt-4">
      <date-picker
        label="Effective Date"
        [controlName]="financeForm?.get('effective_date')"
      ></date-picker>
      <date-picker
        label="End Date"
        [controlName]="financeForm?.get('end_date')"
      ></date-picker>
      <checkbox
        id="fee_change"
        label="Fee Change"
        [controlName]="financeForm?.get('fee_change')"
      ></checkbox>
    </section>

    <div class="flex flex-row justify-center pt-5 w-full">
      <button
        (click)="onBtnClick()"
        [disabled]="disableSubmit"
        class="bg-blue-500 hover:bg-blue-700 text-white w-full text-center font-bold border-2 rounded-md py-1 px-2"
        [ngClass]="{
          'opacity-50 cursor-not-allowed pointer-events-none': disableSubmit
        }"
      >
        Submit
      </button>
    </div>
  </div>`,
})
export class UploadFileDialogComponent {
  public fileType: 'file' | 'image' = 'image';
  public files: File[] = [];
  public disableInput: boolean = false;
  public fileFilter: string = 'image/jpeg,image/jpg,image/png';
  public title: string = 'Upload file';
  public placeholder: string = 'Select a pdf and doc file';
  public multipleFiles: boolean = false;
  public alertMessage: string = null;
  public maxSize: number = 25 * 1024 * 1024; //25 MB
  public maxFileNumber: number = 0; //25 MB
  public isFinanceSetting: boolean = false;

  public financeForm?: FormGroup = null;

  constructor(
    protected dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected snackbar: MatSnackBar,
    protected _fb: FormBuilder
  ) {}

  ngOnInit() {
    if (this.data) {
      this.title = this.data.title ? this.data.title : this.title;
      this.placeholder = this.data.placeholder
        ? this.data.placeholder
        : this.placeholder;
      this.fileFilter = this.data.accept;
      this.fileType = this.data.type ?? 'image';
      this.multipleFiles = this.data.multipleFiles ?? false;
      this.alertMessage = this.data.alertMessage ?? null;
      this.maxSize = this.data.maxSize ?? 25 * 1024 * 1024; //25 MB
      this.maxFileNumber = this.data.maxFileNumber ?? 0;
      this.isFinanceSetting = this.data.isFinanceSetting ?? false;

      if (!!this.isFinanceSetting) {
        this.financeForm = new FormGroup({
          effective_date: new FormControl('', [Validators.required]),
          end_date: new FormControl('', [Validators.required]),
          fee_change: new FormControl(false, [Validators.required]),
        });
      }
    }
  }

  loadImage(f) {
    return f ? URL.createObjectURL(f) : '';
  }

  public onUploadFile(event) {
    if (this.files.length > 0) {
      this.files = [];
    }

    for (let file of event.addedFiles) {
      if (file.size > this.maxSize) {
        const maxSizeInMB = (this.maxSize / (1024 * 1024)).toFixed(2);
        openSnackBar(
          'File size exceeds the maximum limit of ' + maxSizeInMB + ' MB',
          this.snackbar
        );
        return;
      }
    }

    this.files.push(...event.addedFiles);
  }

  public onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  public onClose() {
    this.dialogRef.close();
  }

  public get disableSubmit() {
    if (this.maxFileNumber > 0 && this.files?.length > this.maxFileNumber) {
      return true;
    } else {
      return false;
    }
  }

  public onBtnClick() {
    if (this.isFinanceSetting) {
      this.financeForm.markAllAsTouched();
      if (!this.financeForm.valid || !this.financeForm.valid) {
        return openSnackBar(
          'Please fill up the necessary fields correctly',
          this.snackbar
        );
      }
      this.dialogRef.close({
        data: this.files,
        additional_data: this.data.additional_data,
        finance_settings: this.financeForm.value,
      });
    } else {
      this.dialogRef.close({
        data: this.files,
        additional_data: this.data.additional_data,
      });
    }
  }

  public roundUp(num) {
    const mb = num / 1000000;
    return Math.round(mb * 10) / 10;
  }

  public regex(title: string) {
    const re = /(?:\.([^.]+))?$/;
    const fileType = re.exec(title)[1];
    return fileType;
  }

  public isImage(file: File) {
    const fileType = file.type.split('/').pop();
    return ['png', 'jpg', 'jpeg'].includes(fileType);
  }
}
