import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  template: `<mat-icon
    svgIcon="mat_outline:info"
    [matTooltip]="text"
    [matTooltipPosition]="position"
    [matTooltipShowDelay]="showDelay"
    [matTooltipHideDelay]="hideDelay"
    class="text-blue-500"
  ></mat-icon>`,
})
export class TooltipComponent {
  @Input() text: string = 'Info about the action';
  @Input() position: 'after' | 'before' | 'above' | 'below' | 'left' | 'right' =
    'below';
  @Input() showDelay = 300; // ms
  @Input() hideDelay = 300; // ms
  constructor() {}
}
