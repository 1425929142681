import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { capitalize, get, replace } from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'crud-field-image',
  template: `
    <div class="flex flex-col mb-4">
      <div class="font-bold capitalize">{{ label }}</div>
      <div class="ml-2 mt-1">
        <img
          [src]="value | secure | async"
          *ngIf="value"
          [ngClass]="{
            'm-auto rounded-full h-20 w-20 overflow-hidden': rounded,
            'max-w-52 border border-gray-400': !rounded
          }"
          [alt]="alt"
        />
        <div class="ml-2" *ngIf="!value">{{ value ?? '-' }}</div>
      </div>
    </div>
  `,
})
export class CrudFieldImageComponent implements OnChanges, OnInit {
  @Input() resource: any;
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() value: string = null;
  @Input() alt: string = null;
  @Input() rounded: boolean = false;
  imageUrl: string;
  public signatureValue: string;

  constructor(private http: HttpClient) {}

  public ngOnInit(): void {
    if (this.label == '') {
      this.label = capitalize(replace(this.name, /_/g, ' '));
    }
  }

  public ngOnChanges(changes): void {
    if (!this.value) {
      this.signatureValue = get(this.resource, this.name);
    } else {
      this.signatureValue = this.value;
    }
  }
}
