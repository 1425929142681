import { Component, Input, OnInit } from '@angular/core';
import { appConfig } from 'app/core/config/app.config';
import { SecurePipe } from '../crud/pipes/secure.pipe';

@Component({
  selector: 'attachment-preview',
  templateUrl: './attachment-preview.component.html',
})
export class AttachmentPreviewComponent implements OnInit {
  @Input() file: string;
  @Input() size: number;
  @Input() date: string;
  @Input() showDownloadButton: boolean = true;
  @Input() iconWidth: string = 'w-10';
  @Input() hideInfo: boolean = false;
  @Input() rawFile?: File = null;

  fileName: string;
  extension: string;
  fileSize: string;
  icon: string;
  isExternal = false;

  ngOnInit() {
    this.fileName = this.getFileName();
    this.extension = this.getFileExtension();
    this.fileSize = this.formatFileSize();

    if (this.extension === 'pdf') {
      this.isExternal = false;
      this.icon = 'assets/images/extensions/pdf-icon.svg';
    } else if (['doc', 'docx'].includes(this.extension)) {
      this.isExternal = false;
      this.icon = 'assets/images/extensions/doc-icon.svg';
    } else if (['xls', 'xlsx'].includes(this.extension)) {
      this.isExternal = false;
      this.icon = 'assets/images/extensions/xls-icon.svg';
    } else if (this.rawFile && this.rawFile.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.isExternal = false;
        this.icon = event.target.result;
      };
      reader.readAsDataURL(this.rawFile);
    } else if (['png', 'jpg', 'jpeg', 'gif'].includes(this.extension)) {
      this.isExternal = true;
      this.icon = this.file;
    } else {
      this.isExternal = false;
      this.icon = 'assets/images/extensions/undefined-icon.svg';
    }
  }

  getFileExtension() {
    const extension = this.file.split('.').pop();

    return extension;
  }

  getFileName() {
    const filename = this.file.split('/').pop();

    return filename;
  }

  async handleFile(isDownload: boolean = true) {
    try {
      const token = localStorage.getItem('accessToken') ?? '';
      const { secureApiStorage } = appConfig;
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(this.file, secureApiStorage ? options : {});
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      if (isDownload) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.getFileName());
        link.click();
      } else {
        window.open(url, '_blank');
      }
    } catch (error) {
      console.error('Error handling file:', error);
    }
  }

  // convert size to bytes, KB, MB, or GB
  formatFileSize(): string {
    if (this.size < 1024) {
      return this.size + ' bytes';
    } else if (this.size < 1048576) {
      return (this.size / 1024).toFixed(2) + ' KB';
    } else if (this.size < 1073741824) {
      return (this.size / 1048576).toFixed(2) + ' MB';
    } else {
      return (this.size / 1073741824).toFixed(2) + ' GB';
    }
  }
}
