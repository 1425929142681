import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-header',
  template: `<div class="flex flex-col h-screen overflow-hidden">
    <div class="w-full px-4 py-4 bg-white border-b border-gray-200 sm:px-6">
      <div class="flex items-center">
        <div>
          <img class="w-8 mr-4" src="assets/images/logo/AWWA-logo.png" />
        </div>
        <p class="font-semibold text-2xl">{{ title }}</p>
        <ng-content select="[header]"></ng-content>
      </div>
    </div>
    <div class="flex-1 overflow-y-scroll overscroll-none">
      <div class="max-w-6xl mx-auto px-4 pb-10">
        <ng-content></ng-content>
      </div>
    </div>
  </div>`,
})
export class PublicHeaderComponent implements OnInit {
  @Input() title: string;

  ngOnInit(): void {}
}
