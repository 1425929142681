import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { AtomModule } from '../atoms/atom.module';
import { SharedModule } from '../shared.module';
import { CrudFieldHtmlComponent } from './field-html.component';
import { CrudFieldImageComponent } from './field-image.component';
import { CrudFieldComponent } from './field.component';
import { LayoutDetailComponent } from './layout-detail';
import { LayoutListWithSearchComponent } from './layout-list-with-search';
import { CrudFieldArrayComponent } from './field-array.component';
import { MatIconModule } from '@angular/material/icon';
import { SecurePipe } from './pipes/secure.pipe';

@NgModule({
  declarations: [
    CrudFieldComponent,
    LayoutListWithSearchComponent,
    LayoutDetailComponent,
    CrudFieldImageComponent,
    CrudFieldHtmlComponent,
    CrudFieldArrayComponent,
    SecurePipe,
  ],
  exports: [
    CrudFieldComponent,
    LayoutListWithSearchComponent,
    LayoutDetailComponent,
    FuseLoadingBarModule,
    CrudFieldImageComponent,
    CrudFieldHtmlComponent,
    CrudFieldArrayComponent,
    SecurePipe,
  ],
  imports: [RouterModule, AtomModule, CommonModule, MatIconModule],
})
export class CrudModule {}
