import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';
import { AutocompleteInputComponent } from './autocomplete/autocomplete.component';
import { AvailableSlotComponent } from './available-slot/available-slot.component';
import { ButtonDropdownComponent } from './button-dropdown/button-dropdown.component';
import { ButtonComponent } from './button/button.component';
import { CheckBoxComponent } from './checkbox/checkbox.component';
import { ContainerComponent } from './container/container.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MonthPickerComponent } from './date-picker/month-picker.component';
import { DropdownCheckboxComponent } from './dropdown-checkbox/dropdown-checkbox.component';
import { FormValidatorComponent } from './form-validator/form-validator.component';
import { HeaderComponent } from './header/header.component';
import { PublicHeaderComponent } from './header/public-header.component';
import { InformationPanelComponent } from './information-panel/information-panel.component';
import { LastUpdatedByComponent } from './last-updated-by/last-updated-by.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PagePortal } from './page-portal/portal-page.component';
import { PanelComponent } from './panel/panel.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { StatusComponent } from './status/status.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { UploadFileDialogComponent } from './upload-file/upload-file.component';
import { WeekFrequencyInputComponent } from './week-frequency-input/week-frenquency-input.component';
import { WeeklyScheduleComponentComponent } from './weekly-schedule/weekly-schedule.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { TooltipComponent } from './tooltip/tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MatDividerModule } from '@angular/material/divider';
import { FeeMattersViewerComponent } from './fee-matters-viewer/fee-matters-viewer.component';
import { CrudModule } from './crud/crud.module';
import { NoteFieldComponent } from './note-label/note-field.component';
import { VimeoVideoComponent } from './vimeo-video/vimeo-video.component';
@NgModule({
  declarations: [
    StatusComponent,
    ButtonComponent,
    HeaderComponent,
    TextInputComponent,
    DatePickerComponent,
    MonthPickerComponent,
    ToggleButtonComponent,
    CheckBoxComponent,
    ContainerComponent,
    ButtonDropdownComponent,
    InformationPanelComponent,
    AttachmentPreviewComponent,
    AvailableSlotComponent,
    PanelComponent,
    WeeklyScheduleComponentComponent,
    LastUpdatedByComponent,
    PagePortal,
    PublicHeaderComponent,
    NotFoundComponent,
    SelectInputComponent,
    FormValidatorComponent,
    TextAreaComponent,
    RadioButtonComponent,
    TimePickerComponent,
    SearchDropdownComponent,
    AutocompleteInputComponent,
    WeekFrequencyInputComponent,
    UploadFileDialogComponent,
    MenuButtonComponent,
    DropdownCheckboxComponent,
    TooltipComponent,
    ContactUsComponent,
    FeeMattersViewerComponent,
    NoteFieldComponent,
    VimeoVideoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    AngularSignaturePadModule,
    NgxMatTimepickerModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxDropzoneModule,
    MatCheckboxModule,
    MatRippleModule,
    MatTooltipModule,
    MatDividerModule,
    CrudModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StatusComponent,
    ButtonComponent,
    HeaderComponent,
    TextInputComponent,
    DatePickerComponent,
    MonthPickerComponent,
    ToggleButtonComponent,
    CheckBoxComponent,
    ContainerComponent,
    ButtonDropdownComponent,
    InformationPanelComponent,
    AttachmentPreviewComponent,
    AvailableSlotComponent,
    PanelComponent,
    WeeklyScheduleComponentComponent,
    LastUpdatedByComponent,
    PagePortal,
    PublicHeaderComponent,
    NotFoundComponent,
    SelectInputComponent,
    FormValidatorComponent,
    TextAreaComponent,
    RadioButtonComponent,
    TimePickerComponent,
    MatMomentDateModule,
    SearchDropdownComponent,
    AutocompleteInputComponent,
    WeekFrequencyInputComponent,
    UploadFileDialogComponent,
    MenuButtonComponent,
    DropdownCheckboxComponent,
    MatSlideToggleModule,
    MatTabsModule,
    TooltipComponent,
    ContactUsComponent,
    MatDividerModule,
    FeeMattersViewerComponent,
    CrudModule,
    NoteFieldComponent,
    VimeoVideoComponent,
  ],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
})
export class SharedModule {}
