import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'button-dropdown',
  templateUrl: './button-dropdown.component.html',
})
export class ButtonDropdownComponent implements OnInit {
  @Input() options = [];
  @Input() type: 'default' | 'form' = 'default';
  @Input() placeholder: string = 'Select an option';
  @Input() controlName: FormControl = new FormControl('');
  @Output() outputValue: EventEmitter<void> = new EventEmitter();
  ngOnInit() {}

  onSelect(event) {
    this.outputValue.emit(event.target.value);
  }
}
