import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckboxTheme } from 'app/services/api/models/checkbox-theme';

@Component({
  selector: 'checkbox',
  template: `
    <div *ngIf="type == 'checkbox'" class="flex items-center">
      <input
        type="checkbox"
        [id]="id"
        [formControl]="controlName"
        class="form-checkbox rounded-full h-4 w-4 text-blue-500 transition duration-150 ease-in-out"
        (change)="Clicked()"
      />
      <label [for]="id" (click)="labelClick()" class="ml-2">
        {{ label }}
      </label>
    </div>

    <div
      *ngIf="type == 'checkbox-button'"
      class="bg-white gap-1 py-1 pr-[-100%] pl-2 border-blue-500 border-2 rounded-md inline-flex items-center"
    >
      <label (click)="labelClick()" class="text-blue-500 font-bold">
        {{ label }}
      </label>
      <mat-checkbox
        class="mb-[-4%] mt-[-3.5%]"
        disableRipple="true"
        [formControl]="controlName"
        [color]="checkboxConfig.color"
        (change)="Clicked()"
      >
      </mat-checkbox>
    </div>
  `,
})
export class CheckBoxComponent implements OnInit {
  @Input() label: string = 'Required';
  @Input() controlName: FormControl;
  @Input() id: string = '';
  @Output() onClick: EventEmitter<void> = new EventEmitter();
  @Input() type: string = 'checkbox';

  public toggle: boolean = false;
  public checkboxConfig: CheckboxTheme = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
  };

  ngOnInit(): void {}

  public Clicked() {
    this.onClick.emit();
  }

  public labelClick() {
    this.controlName.setValue(!this.controlName.value);
    this.Clicked();
  }
}
