<div>
  <div class="w-fit flex flex-col" *ngIf="file">
    <section class="flex items-center w-full">
      <!-- File Icon -->
      <span class="grid place-items-center p-2">
        <ng-container [ngSwitch]="isExternal">
          <crud-field-image
            *ngSwitchCase="true"
            [value]="icon"
            [alt]="fileName"
            [class]="iconWidth"
          />
          <img *ngSwitchDefault [src]="icon" alt="file" [class]="iconWidth" />
        </ng-container>
      </span>

      <!-- Description -->
      <span class="flex flex-col break-words max-w-100">
        <div class="flex flex-row items-center">{{ fileName }}</div>
        <p *ngIf="(size || date) && !hideInfo" class="text-sm text-gray-500">
          <span *ngIf="size">{{ fileSize }}</span>
          <span *ngIf="size && date"> | </span>
          <span *ngIf="date">{{ date }}</span>
        </p>
      </span>
    </section>

    <ng-container [ngSwitch]="showDownloadButton">
      <!-- Download File -->
      <app-button
        *ngSwitchCase="true"
        (onClick)="handleFile()"
        type="link"
        text="Download"
      >
      </app-button>
      <!-- Pre-view File -->
      <app-button
        *ngSwitchDefault
        (onClick)="handleFile(false)"
        type="link"
        text="Preview"
      >
      </app-button>
    </ng-container>
  </div>
</div>
